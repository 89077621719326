'use strict';

import { DotLottie } from '@lottiefiles/dotlottie-web';

const coupleCanvas = document.getElementById('intro')
const couple = new DotLottie({
    canvas: coupleCanvas,
    src: "images/couple-landing.lottie"
});

setTimeout(() => couple.play(), 1000);

coupleCanvas.addEventListener('click', function() {
	couple.stop();
	couple.play();
})
